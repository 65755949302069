// src/components/Footer.js
import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-info">
          <p className="footer-company">&copy; 2024 Task Driven Solutions</p>
          <p>Business Process Automation Experts</p>
          <p>
            Contact us at{' '}
            <a href="mailto:info@taskdrivensolutions.com" className="footer-email">
              info@taskdrivensolutions.com
            </a>
          </p>
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com/profile.php?id=61567268301327" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebook size={28} />
          </a>
          {/* <a href="https://twitter.com/yourpage" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaTwitter size={28} />
          </a> */}
          <a href="https://www.linkedin.com/company/105247694" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <FaLinkedin size={28} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
