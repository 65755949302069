// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './App.css';
import Contact from './pages/Contact';
import Features from './pages/Features';
import Header from './components/Header';
import Footer from './components/Footer';
import { features,pitches } from './misc/objects';

function Home() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      
      {/* Hero Section with Carousel */}
      <section className="hero">
        <div className="hero-content">
          <h1>Transform Your Business with Seamless Automation</h1>
          <p>Unlock new levels of efficiency and productivity with custom automation solutions that take your business to the next level.</p>
        </div>

        {/* Carousel within Hero */}
        <div className="carousel-container">
          <Carousel autoPlay infiniteLoop showThumbs={true} showStatus={false} showIndicators={false}>
            {features.map((feature, index) => (
              <div key={index} className="carousel-slide">
                <img src={feature.image} alt={feature.title} />
                <div className="carousel-caption">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="gs-buttons">
            <button className="cta-button" onClick={() => navigate('/contact')}>
              Start Your Automation Journey
            </button>
            <button className="cta-button secondary-button" onClick={() => navigate('/features')}>
              Discover Our Solutions
            </button>
          </div>       
      </section>

      {/* Features Section */}
      <section className="features">
        <h2>Why Businesses Trust Us for Automation</h2>
        <div className="carousel-container">
          <Carousel autoPlay infiniteLoop showThumbs={true} showStatus={false} showIndicators={false}>
              {pitches.map((pitches, index) => (
                <div key={index} className="carousel-slide">
                  <img src={pitches.image} alt={pitches.title} />
                  <div className="carousel-caption">
                    <h3>{pitches.title}</h3>
                    <p>{pitches.description}</p>
                  </div>
                </div>
              ))}
           </Carousel>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works">
        <h2>How It Works</h2>
        <p>Discover how our tailored automation process simplifies and enhances your daily business operations, step by step.</p>
        <div className="how-it-works-image">
          <img src={`${process.env.PUBLIC_URL}/assets/images/how-it-works.png`} alt="How It Works" />
        </div>
      </section>

      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/features" element={<Features />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
