// Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  const navigate = useNavigate(); // Enable navigation

  return (
    <header className="app-header">
      <div className="logo-container" onClick={() => navigate('/')}>
        <img 
          src={`${process.env.PUBLIC_URL}/assets/images/company-logo.webp`} 
          alt="Company Logo" 
          className="header-logo" 
        />
      </div>
      {/* <div className="header-cta">
        <Link to="/features" className="cta-button">Explore Features</Link>
      </div> */}
    </header>
  );
}

export default Header;
