// Contact.js
import React, { useState } from 'react';
import Header from '../components/Header';
import { sendContactInfo } from '../misc';
import Footer from '../components/Footer';
import '../styles/Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    description: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email.includes('@')) {
      newErrors.email = 'Oops! Please enter a valid email address so we can reach you.';
    }
    if (formData.phone.length < 10) {
      newErrors.phone = 'Phone number must be at least 10 digits for us to contact you.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setResponseMessage('Thank you for reaching out! Our team will get back to you within 1 business day to discuss how we can help.');
      await sendContactInfo(formData);
      setFormData({
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        description: '',
      });
    }
  };

  return (
    <>
      <Header />
      <div className="contact">
        <h2>Contact Us</h2>
        <p className="contact-description">
          Ready to take the next step toward seamless automation and increased productivity? Our experts are here to provide tailored solutions. Simply fill out the form below, and we'll connect with you to discuss how we can help your business thrive.
        </p>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label>Your First Name *</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Your Last Name *</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Company Name *</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Your Email Address *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div className="form-group">
            <label>Your Phone Number *</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
          </div>
          <div className="form-group">
            <label>Tell Us About Your Project or Needs *</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="submit-button">Get a Free Consultation</button>
        </form>
        <p id="responseMessage" className="response-message">{responseMessage}</p>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
