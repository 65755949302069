export const sendContactInfo = async (contactInfo) => {
    fetch('https://platform.taskdrivensolutions.com/webhook/407f4fa0-b533-4f16-b9fc-b79d52948750', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(contactInfo)
      })
      .then(response => response.text())
      .then(data => {
        // Show success message
        document.getElementById('responseMessage').textContent = 'Data sent successfully!';
      })
      .catch(error => {
        // Show error message
        document.getElementById('responseMessage').textContent = 'An error occurred: ' + error;
      })
}